import React from "react";

export function EnglishText() {
  return (
    <div className="credits-holder grotzec-light">
      <p> English</p>
      <p>
        La Marea (The Tide) is an essay documentary that depicts the life of
        Jorge, and his hometown in Seybaplaya, Mexico. Seybaplaya is a town
        sustained mainly by the fishing industry but is rapidly changing due to
        mass cargo fleets and the new oil platform. While these activities
        represent indirect economic growth, they also indicate a break in
        culture and resource consumption. Jorge represents the contemporary
        Mexican youth, whose hometowns are undergoing rapid changes by
        profit-driven industries. But rather than drive economic development,
        these changes force migration and displacement — Jorge’s dream of
        staying at home is the Mexican dream.
      </p>

      <p>
        La Marea : La Corriente (The Tide : The Stream) is a web-based
        documentary interface that allows the spectator to restructure the
        narrative into a personal and serendipitous exploration of Seybaplaya.
        An immersive screening component highlights the space and transports the
        viewer to sit with Jorge and Miguel — a rendition of a fictitious and
        fantastical cinematic theater. La Marea, in its documentary form, has no
        fixed state. It rejects the placement of time and invites the viewer to
        let go into the stream. La Corriente is a platform for multiple portals
        to Seybaplaya, offering different sequences of information that allow
        each spectator to have a different experiential narrative.
        The project was developed by <a href="https://miguelnovelo.com/" target="_blank">Miguel Novelo</a> in collaboration with Jorge
        Carbajal, Carlos Camargo, <a href="https://www.linkedin.com/in/felixehuan/" target="_blank">Félix Ehuan</a>, and <a href="https://www.cineo.la/" target="_blank">CiNEOLA</a>.
      </p>

      <p>
        Without a doubt, this is a story of the tropical southeast of Mexico but
        conceived in the Bay Area, where innovation and media creation are
        embedded into the culture. The documentary was created as an interactive
        narrative since the beginning of the project, where viewers could either
        leave with a superficial view of Seybaplaya or decide to dive in,
        placing themselves in different moments in time, and only then, get a
        clearer picture of the life, space/time, and culture of the place. Only
        to again be disjointed by the short “social-media-esque” duration and
        multiplicity of the scenes. The tide would always stay in perpetual
        change, and so is life in Seybaplaya.
      </p>
    </div>
  );
}
