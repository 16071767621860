import {useState} from 'react'  
// Hook
export const usePlayer = (overridingState) =>{
    // State and setters for debounced value
    const initialState = {
        url: '',
        pip: false,
        playing: true,
        controls: false,
        light: false,
        volume: 0.8,
        muted: true,
        played: 0,
        loaded: 0,
        duration: 0,
        playbackRate: 1.0,
        loop: true,
      }
    const [state, setState] = useState({...initialState,...overridingState});
    return [state, setState];
   
  }