import React,{useState, useEffect} from 'react';
import {useDebounce} from '../hooks/useDebounce';

export const Mark = ({
    playerConfig,
    setPlayerConfig,
    externalSrcConfig,
    setExternalSrcConfig,
    url,
    id,
    mstyle,
    setOpen,
    setSoundMainState,
    setMarkHover,
    setTransformParams}) => {
    const [hovered, setHovered] = useState(false);
    const debouncedEvent = useDebounce(hovered, 300);
    var mclass = id===360?"Mark Mark-360":"Mark";
    useEffect(
        () => {
          if (debouncedEvent) {
               setPlayerConfig({...playerConfig, playing: false});
          } else {
               setPlayerConfig({...playerConfig, playing: true})
          }
        },
        [debouncedEvent]
      );


    return (

        <button className={mclass} style={mstyle}
             onMouseEnter={(e) => {
                 console.log('hovered');
                 setHovered(true);
                 setMarkHover({isMarkHover:true,id:id})
                 setTransformParams({duration:"2s",posX:`${mstyle.left}`,posY:`${mstyle.top}`,scale:"1.1"})
                }
             }
             onMouseLeave={() => {
                setHovered(false);
                setMarkHover({isMarkHover:false,id:-1})
                 setTransformParams({duration:"2s",posX:mstyle.left,posY:mstyle.top,scale:"1"})
             }
             }
             onClick={()=>{
                 setExternalSrcConfig({...externalSrcConfig,url:url})
                 setOpen(true)
                 setSoundMainState(true);

             }}
        >
        </button>
    );
};