import React,{useState, useEffect} from 'react';
import {useDebounce} from '../hooks/useDebounce';

export const MarkI = ({
    playerConfig,
    setPlayerConfig,
    mstyle,
    setVisibility,
    }) => {
    const [hovered, setHovered] = useState(false);
    const debouncedEvent = useDebounce(hovered, 500);
    useEffect(
        () => {
          if (debouncedEvent) {
              setVisibility("hidden");
              setPlayerConfig({...playerConfig, playing: true});
          } else {
               //setPlayerConfig({...playerConfig, playing: true})
          }
        },
        [debouncedEvent]
      );


    return (
        <button className='MarkI' style={mstyle}
             onMouseEnter={(e) => {
                 //setPlayerConfig({...playerConfig, playing: false});
                 console.log('hovered');
                 //setHovered(true);

             }
             }
             onMouseLeave={() => {
                setHovered(false);
             }
             }
             onClick={()=>{
                 setPlayerConfig({...playerConfig, playing: false});
                 console.log('hovered');
                 setHovered(true);
             }}
        >
        </button>
    );
};