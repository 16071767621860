
  export const marks = [
    {
      url: "https://vimeo.com/447606708/bfe48c679a",
      classN: "MContainerZoom0",
      id: 3,
      styles: {
        width: "9vh",
        height: "9vh",
        top: "20vh",
        left: "12vw",
      },
    },
    {
      url: "https://vimeo.com/452090271/7ffe01a3b1",
      classN: "MContainerZoom0",
      id: 8,
      styles: {
        width: "9vh",
        height: "9vh",
        top: "38vh",
        left: "23vw",
        animationDelay:'1s'
      },
    },
    {
      url: "https://vimeo.com/447591281/daf2d78193",
      classN: "MContainerZoom0",
      id: 10,
      styles: {
        width: "9vh",
        height: "9vh",
        top: "22vh",
        left: "35vw",
        animationDelay:'7s'
      },
    },
      {
      url: "https://youtu.be/RHAUfsK_iP0",
      id:360,
      classN: "MContainerZoom0",
      styles: {
        width: "9vh",
        height: "9vh",
        top: "48vh",
        left: "40vw",
        animationDelay:'10s'
      },
    },
    {
      url: "https://vimeo.com/451971136/889c0f2306",
      id: 12,
      classN: "MContainerZoom0",
      styles: {
        width: "9vh",
        height: "9vh",
        top: "70vh",
        left: "40vw",
        animationDelay:'19s'
      },
    },
    {
      url: "https://vimeo.com/447600915/3be8beea6f",
      id: 11,
      classN: "MContainerZoom0",
      styles: {
        width: "9vh",
        height: "9vh",
        top: "30vh",
        left: "50vw",
        animationDelay:'13s'
      },
    },
    {
      url: "https://vimeo.com/451322359/8cc2094241",
      id: 4,
      classN: "MContainerZoom0",
      styles: {
        width: "9vh",
        height: "9vh",
        top: "42vh",
        left: "65vw",
        animationDelay:'4s'
      },
    },
    {
      url: "https://vimeo.com/447604557/d063b82a42",
      id: 1,
      classN: "MContainerZoom0",
      styles: {
        width: "9vh",
        height: "9vh",
        top: "32vh",
        left: "79vw",
        animationDelay:'16s'
      },
    },
    {
      url: "https://vimeo.com/447844937/267feb8d17",
      id: 2,
      classN: "MContainerZoom0",
      styles: {
        width: "9vh",
        height: "9vh",
        top: "52vh",
        left: "2vw",
        animationDelay: '2s'
      },
    },
    {
      url: "https://vimeo.com/451684274/1d3f727738",
      id:7,
      classN: "MContainerZoom0",
      styles: {
        width: "9vh",
        height: "9vh",
        top: "82vh",
        left: "7vw",
        animationDelay:'7s'
      },
    },
    {
      url: "https://vimeo.com/451678779/2ed63e12ad",
      id:6,
      classN: "MContainerZoom0",
      styles: {
        width: "9vh",
        height: "9vh",
        top: "15vh",
        left: "69vw",
        animationDelay:'9s'
      },
    },
    {
      url: "https://vimeo.com/452233773/ce5bbbfc82",
      id:9,
      classN: "MContainerZoom0",
      styles: {
        width: "9vh",
        height: "9vh",
        top: "50vh",
        left: "89vw",
        animationDelay:'14s'
      },
    },
    {
      url: "https://vimeo.com/451337846/e0a2273e09",
      id:5,
      classN: "MContainerZoom0",
      styles: {
        width: "9vh",
        height: "9vh",
        top: "76vh",
        left: "91vw",
        animationDelay:'19s'
      },
    },

  ];