import React from 'react'

export function CreditsText() {
    return (
        <>
          <h2 className="cinzel credits-title">Creditos</h2>
          <div className="credits-holder mb-0 center cinzel">
              <p>Director, Editor y Productor</p>
              <p>Miguel Novelo</p>
              <p>Protagonista y Composición Musical</p>
              <p> Jorge Carbajal</p>
              <p>Drone La Marea</p>
              <p>Pablo Tut</p>
              <p>Drone La Marea</p>
              <p>La Corriente</p>
              <p>Carlos Uco</p>
              <p>Banda Sonora La Marea</p>
              <p>Jairo David Chi </p>
              <p>Productor CINEOLA</p>
              <p>Daniel Díaz</p>
              <p>Lead Developer</p>
              <p>Carlos Camargo</p>
              <p>Executive Web Development</p>
              <p>Félix Ehuan </p>
          </div>
          <div className="credits-holder mb-0 center cinzel">
            <p>Agradecimientos Especiales</p>
            <p>Amigos y Familia de Jorge</p>
            <p>Miguel Novelo Piña y Erica Cruz Sábido</p>
            <p>Stephanie Smith</p>
            <p>Tin Dirdamal</p>
            <p>Melanie Piech</p>
            <p>Christopher Coppola</p>
            <p>Kerry Laitala</p>
            <p>Angela Aviña</p>
            <p>Leticia Durán</p>
            <p>Janine Rincón</p>
            <p>Magali Rocha</p>
            <p>Marco Castaneda</p>
          </div>   
        </>
    )
}
