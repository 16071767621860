import "./App.css";
import React, { useState ,useEffect} from "react";
import ReactPlayer from "react-player";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import {isMobile, isTablet} from 'react-device-detect'
import {marks} from './constants';
import {SpanishText, EnglishText, Mark, LoadingSlice, Modal, CreditsText} from "./components";
import {imgB, textB, bgCredits, festivales, cineola,snap} from "./assets"
import { usePlayer } from "./hooks/usePlayer";
// import styled from "styled-components";
function App() {
 
  const video1 =
    "https://firebasestorage.googleapis.com/v0/b/la-marea-la-corriente.appspot.com/o/masterbg.mp4?alt=media&token=28892e7c-5be4-4811-94b4-4b423331bd2f";
  const song =
    "https://firebasestorage.googleapis.com/v0/b/la-marea-la-corriente.appspot.com/o/LA%20MAREA%20LA%20CORRIENTE%2028%20AGOSTO%203%20MB.mp3?alt=media&token=16053a23-8b8b-4099-a07c-989b3bfe540b";
  
  
  const [bgReadyFlags, setBgReadyFlags] = useState(false); // show overlay while bg video is buffering
  const [snapShot, setSnapShot] = useState(true); // show overlay while bg video is buffering
  const [isOpen, setOpen] = useState(false);

  const [playerConfig, setPlayerConfig] = usePlayer({url:video1});
  const [songConfig, setSongConfig] = usePlayer({url:song});
  // music player props
  const [soundMainState, setSoundMainState] = useState(true);
  
  const [externalSrcConfig, setExternalSrcConfig] = useState({
    url: "",
  });

  const [isMarkHover, setMarkHover] = useState({ isMarkHover: false, id: -1 });
  const [transformParams, setTransformParams] = useState({
    duration: "0.1s",
    posX: "0",
    posY: "0",
    scale: "1",
  });

  const makProps = {
    playerConfig,
    setPlayerConfig,
    externalSrcConfig,
    setExternalSrcConfig,
    setOpen,
    setSoundMainState,
    setMarkHover,
    setTransformParams,
  };


  return (
    <>
    <section className="App">
      <TransitionGroup>
        {isMobile && isTablet && <LoadingSlice playerConfig={playerConfig} setPlayerConfig={setPlayerConfig} srcBg={imgB} srcText={textB} />}
        {isMobile && !isTablet && <LoadingSlice playerConfig={playerConfig} setPlayerConfig={setPlayerConfig}srcBg={imgB} srcText={textB} />}
        {!isMobile&&!bgReadyFlags && (
            <CSSTransition timeout={1000} classNames="appear">
              <LoadingSlice playerConfig={playerConfig} setPlayerConfig={setPlayerConfig} srcBg={imgB} srcText={textB} />
            </CSSTransition>
        )}
      </TransitionGroup>
          <div className="MContainer"style={{transform: `scale(${transformParams.scale})`, transformOrigin: `${transformParams.posX} ${transformParams.posY}`, transition: `transform ${transformParams.duration}`}}>
            {snapShot && <img src={snap} className="Snap"/>}

            <VideoPlayer
              setBgReadyFlags={setBgReadyFlags}
              playerConfig={playerConfig}
              setPlayerConfig={setPlayerConfig}
              setSnapShot={setSnapShot}
            />

            <AudioPlayer
              soundMainState={soundMainState}
              songConfig={songConfig}
              setSongConfig={setSongConfig}
            />
            <SoundIcon
              soundMainState={soundMainState}
              setSoundMainState={setSoundMainState}
              setSongConfig={setSongConfig}
              songConfig={songConfig}
            />

              {marks.map((mark, idx) => {
                if (isMarkHover.isMarkHover) {
                  if (marks[idx].id === isMarkHover.id)
                    return (
                      <Mark
                        cName="Mark"
                        key={idx}
                        url={mark.url}
                        {...makProps}
                        id={mark.id}
                        mstyle={mark.styles}
                      />
                    );
                } else
                  return (
                    <Mark
                      cName="Mark"
                      key={idx}
                      url={mark.url}
                      {...makProps}
                      id={mark.id}
                      mstyle={mark.styles}
                    />
                  );
              })}


              <ModalPlayer
                isOpen={isOpen}
                setOpen={setOpen}
                setPlayerConfig={setPlayerConfig}
                setSoundMainState={setSoundMainState}
                playerConfig={playerConfig}
                url={externalSrcConfig.url}
              />
            </div>
      </section>
      <section className="App flex credits-section justify-content-center align-items-center flex-wrap"
      style={{ backgroundImage: `url(${bgCredits})`, backgroundPosition:'top',backgroundRepeat:'no-repeat', backgroundSize:'contain' }}>
        <div className="credits-overlay"></div>
        <div className="container flex justify-content-between flex-wrap">
          <p className="cinzel credits-title">La marea: La corriente</p>
          <SpanishText />
          <EnglishText />
          <CreditsText />
        </div>
        <div className="credits-title" style={{height:'50px'}}></div>
        <div className="cineola-holder" style={{marginBottom:'50px'}}>
            <img src={cineola} alt="cineola logo" className="cineola-img"/>
        </div>
        <div className="festivales-holder">
            <img src={festivales} alt="festivales logo" className="festivales-img"/>
        </div>
        <div className="credits-title" style={{height:'50px',width:'100%'}}></div>
      </section>
    </>
  );
}

export default App;

const ModalPlayer = ({ isOpen, url,...props }) => {
  return (
    <Modal isOpen={isOpen} {...props} >
      {isOpen && (
        <ReactPlayer
          className="VContainer"
          height="100%"
          width="100%"
          url={url}
          config={{
            vimeo: { playerOptions: { controls: true } },
            youtube: { playerVars: { controls: 1, fs: 1 } },
          }}
        />
      )}
    </Modal>
  );
};

const SoundIcon = ({ soundMainState, setSoundMainState, setSongConfig, songConfig}) => {
  return (
    <div
      className="sound-icon-holder"
      onClick={() => {
        if (soundMainState) {
          setSoundMainState(false);
          setSongConfig({ ...songConfig, playing: true, muted: false });
          return;
        }
        setSoundMainState(true);
        setSongConfig({ ...songConfig, playing: false });
      }}
    >
      {soundMainState || !songConfig.playing ? (
        <i className="sound-icon material-icons">volume_off</i>
      ) : (
        <i className="sound-icon material-icons">volume_up</i>
      )}
    </div>
  );
};

const AudioPlayer = ({ songConfig, soundMainState, setSongConfig }) => (
  <ReactPlayer
    className="react-player"
    width="0px"
    height="0px"
    {...songConfig}
    muted={songConfig.muted || soundMainState}
    onReady={() => console.log("onReady")}
    onStart={() => console.log("onStart")}
    onBuffer={() => console.log("onBuffer")}
    onSeek={(e) => console.log("onSeek", e)}
    onError={(e) => {
      setSongConfig({ ...songConfig, playing: false });
    }}
  />
);

function VideoPlayer ({ playerConfig, setBgReadyFlags,setPlayerConfig,setSnapShot })
{
  return <ReactPlayer
      className="react-player"
      width="100vw"
      height="100vh"
      {...playerConfig}
      onReady={() => {
        console.log("onReady");
      }}
      onStart={() => {

        console.log("onStart");
        setTimeout(() => setBgReadyFlags(true), 2000);
        setSnapShot(false);
      }}
      onBuffer={() => console.log("onBuffer")}
      onSeek={(e) => console.log("onSeek", e)}
      onError={(e) => {
        if(!isTablet)setTimeout(() => setBgReadyFlags(true), 2000);
        //setSnapShot(true);
        console.log("onError", e)
      }}
  />
}
