import React from "react";

export function SpanishText() {
  return (
    <div className="credits-holder grotzec-light">
      <p>Español</p>
      <p>
        La Marea es un proyecto de ensayo documental, que retrata la vida de
        Jorge Carbajal y el municipio de Seybaplaya, Campeche México. Jorge es
        un adolescente que busca trascender y poder quedarse en el espacio donde
        nació, Seybaplaya. Dicho municipio es un espacio sustentado por la pesca
        tradicional, y que se encuentra en la mira de la industria petrolera y
        naviera de México. Jorge representa la juventud de un México impulsado
        por industrias que priorizan lo económico, y en vez de generar equidad
        social, fuerzan la migración y el desplazamiento. Jorge desea y sueña
        con permanecer y crecer en su hogar.
      </p>
      <p>
        La Marea : La Corriente es un proyecto independiente documental pensado
        para ser expuesto a través de una interfaz web, donde se le permite al
        espectador reestructurar la narrativa documental y así encontrar una
        mirada personal, improvisada e incierta exploración de Seybaplaya. La
        Marea, el documental, no tiene un formato fijo, se rechaza la idea del
        emplazamiento del tiempo, e invita al espectador a dejarse llevar por la
        corriente. La Corriente funciona como una plataforma de múltiples
        portales hacia Seybaplaya, favorece las herramientas que actúan como
        múltiples canales de información, permitiendo crear diferentes
        narrativas para cada espectador.
      </p>

      <p>
        El proyecto es desarrollado por{" "}
        <a href="https://miguelnovelo.com/" target="_blank">
          Miguel Novelo
        </a>{" "}
        en colaboración con Jorge Carbajal, Carlos Camargo,{" "}
        <a href="https://www.linkedin.com/in/felixehuan/" target="_blank">
          Félix Ehuan
        </a>{" "}
        y{" "}
        <a href="https://www.cineo.la/" target="_blank">
          CiNEOLA
        </a>
        . Sin duda alguna, La Marea es una historia tropical del sureste de
        México, concebida en el Bay Area, donde la innovación y la creación de
        contenido es parte de la cultura de la bahía del norte de California. El
        documental fue concebido pensando en la cualidades interactivas en la
        narrativa de la historia, donde los espectadores podrían irse con una
        mirada abstracta y lejana de Seybaplaya, o decidieron adentrarse,
        explorar distintos momentos en el tiempo, y solo así tener una visión
        clara de la vida, espacio/tiempo, cultura y lugar. Solo para volver a un
        espacio de incoherencia por la corta duración y la multiplicidad de las
        escenas. La marea, el documental y el fenómeno de movimiento marítimo,
        está en constante cambio y movimiento, de igual manera que la vida en
        Seybaplaya.
      </p>
    </div>
  );
}
