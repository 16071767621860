import React, {useState} from 'react';
import {isMobile, isTablet} from 'react-device-detect'
import {MarkI} from "./MarkI";
export const LoadingSlice = ({srcBg,srcText,playerConfig,setPlayerConfig}) => {
    const mClass=!isMobile||isTablet?"loading-banner":"loading-banner RotaryAnim";
    const [visibility,setVisibility] = useState("visible");
    var markp = {
        url: "https://vimeo.com/447606708/bfe48c679a",
        styles: {
            width: "9vh",
            height: "9vh",
            top: "70vh",
            left: "45vw",
        }};
    return (
        <div className={mClass} style={{background:"Black",visibility:visibility}}>
            <img src={srcBg} alt="Loading Overlay" style={{objectFit:'cover'}}/>
            <p className="cinzel loading-title loading-animation">La marea: La corriente</p>
            {isMobile && <i className="material-icons rotate RotaryIco RotaryAnim">screen_rotation</i>}
            {isMobile && isTablet&& ( <MarkI
                setVisibility = {setVisibility}
                playerConfig={playerConfig}
                setPlayerConfig={setPlayerConfig}
                cName="MarkI"
                {...markp}
                mstyle={markp.styles}
            />)}

        </div>
    );



};