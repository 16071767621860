import React from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import {unmuteIco} from "../assets";

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
`;
const ModalContainer = styled(motion.div)`
  width: 75%;
  height: 75%;
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 4s;
  border-radius: 12px;
`;
const CloseButton = styled.svg`np
  width: 20px;
  height: 20px;
  position: absolute;
  left: 18px;
  top: 18px;
  cursor: pointer;
`;

const modalVariant = {
    initial: { opacity: 0  },
    isOpen: { opacity: 1 ,transition: { duration:2}},
    exit: { opacity: 0  }
};
const containerVariant = {
    initial: { top: "-50%", transition: { type: "spring" } },
    isOpen: { top: "50%", },
    exit: { top: "-50%"}
};
export const Modal = ({ setOpen, children, isOpen,setPlayerConfig, playerConfig, setSoundMainState}) => {
    return (
        <AnimatePresence>
            {isOpen && (
                <Overlay
                    initial={"initial"}
                    animate={"isOpen"}
                    exit={"exit"}
                    variants={modalVariant}
                    onClick={()=>{
                        setOpen(false)
                        setPlayerConfig({...playerConfig, playing: true});
                        setSoundMainState({ mute: false, ico: unmuteIco });
                    }}
                >
                    <ModalContainer variants={containerVariant}>
                        {children}
                        <CloseButton
                            onClick={()=>{
                                setOpen(false)
                                setPlayerConfig({...playerConfig, playing: true});
                                setSoundMainState({ mute: false, ico: unmuteIco });
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20.39 20.39"
                        >
                            <title>close</title>
                            <line
                                x1="19.39"
                                y1="19.39"
                                x2="1"
                                y2="1"
                                fill="none"
                                stroke="#FFFFFF"
                                strokeLinecap="round"
                                strokeMiterlimit="10"
                                strokeWidth="2"
                            />
                            <line
                                x1="1"
                                y1="19.39"
                                x2="19.39"
                                y2="1"
                                fill="none"
                                stroke="#FFFFFF"
                                strokeLinecap="round"
                                strokeMiterlimit="10"
                                strokeWidth="2"
                            />
                        </CloseButton>

                    </ModalContainer>
                </Overlay>
            )}
        </AnimatePresence>
    );
};
